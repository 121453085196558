.btn {
  @apply py-2 px-4 rounded-lg font-semibold shadow-lg;
}
.btn-green {
  @apply text-white bg-green-400 hover:bg-green-700;
}
.btn-blue {
  @apply text-white bg-blue-400 hover:bg-blue-700;
}
.btn-red {
  @apply text-white bg-red-400 hover:bg-red-700;
}

.btn-primary {
  @apply text-white bg-primary1 hover:bg-secondary1;
}
.btn-secondary {
  @apply text-gray-800 hover:text-white bg-fourth2 hover:bg-secondary1;
}
